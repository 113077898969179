import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export interface IDotNotationContainerProps {
  children?: ReactNode;
}

export const DotNotationContainer: React.FC<IDotNotationContainerProps> = ({
  children,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      {children}
    </Box>
  );
};
