import React from "react";
import { Section } from "../../../layout/Section";
import { Box, Flex, Text } from "@chakra-ui/react";
import AlignmentContainer from "../../../AlignmentContainer";
import ArticleHeading from "../../../ArticleHeading";
import { BodyText } from "../../../texts/BodyText";
import ArticleImageContainer from "../../../ArticleImageContainer";
import { StaticImage } from "gatsby-plugin-image";
import HeadPara from "../../../HeadPara";
import { DotNotationContainer } from "../../../layout/dotNotation/DotNotationContainer";
import { DotNotationItem } from "../../../layout/dotNotation/DotNotationItem";
import { ContactMaricaCard } from "../../../cards/ContactMaricaCard";

export interface IUiSectionProps {}

export const UiSection: React.FC<IUiSectionProps> = () => {
  return (
    <Section>
      <Flex direction="column" gap="5rem">
        <AlignmentContainer>
          <ArticleHeading subtitle="UI/UX designer" />
          <BodyText>Are you looking for an opportunity in UI/UX?</BodyText>
          <BodyText>
            We at Indevit are looking for a dedicated and skilled designer for a
            temporary position, with the possibility of extension. We need you
            to strengthen our design strategies!
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <Box height="40rem" overflow="hidden">
            <StaticImage
              alt=""
              src="../../../../images/marica_working1.jpg"
              placeholder="none"
              layout="fullWidth"
              transformOptions={{ fit: "inside", cropFocus: "attention" }}
            />
          </Box>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="About the job" />
          <BodyText>
            We are looking for someone with a background in UI/UX design and
            experience in creating user-friendly interfaces. You should be
            capable of working independently as well as part of a team, with
            good communication skills. A big plus is flexibility and the ability
            to adapt to changing requirements and new challenges. We are looking
            for a dedicated designer for a one-year position, with a good
            possibility of extension. The work can start as soon as we find the
            right person.
          </BodyText>
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start">
          <DotNotationContainer>
            <HeadPara medium para="We are looking for someone who is..." />
            <DotNotationItem text="Creative and passionate about well-planned user interfaces and user experiences." />
            <DotNotationItem text="Customer-orientated and able to understand the needs of different users." />
            <DotNotationItem text="Excellent problem-solving skills and a talent for innovative thinking." />
            <DotNotationItem text="Fluent in Swedish or Finnish and English." />
            <DotNotationItem text="Other experience in design, development or marketing is considered an asset! In our daily work we use Figma, Miro and DevOps. Having experience in these programs or their equivalents is seen as an advantage." />
          </DotNotationContainer>
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start">
          <DotNotationContainer>
            <HeadPara medium para="Indevit offers..." />
            <DotNotationItem text="Competitive salary." />
            <DotNotationItem text="Flexible working hours and location (hybrid and/or in-office)." />
            <DotNotationItem text="Good work/life balance." />
            <DotNotationItem text="A great team!" />
          </DotNotationContainer>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/indevit_working.jpg"
            placeholder="none"
            style={{ maxHeight: "40rem" }}
          />
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Application form" />
          <BodyText>
            If you think you are the right person for this job, do not hesitate
            to contact us! Please send your CV, a personal letter and portfolio
            to <b>marica.ost@indevit.fi</b>.
          </BodyText>
          <BodyText>
            We look forward to hearing from you and discussing the possibility
            of working together to create something amazing!
          </BodyText>
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start" marginTop="5rem">
          <HeadPara medium para="Do you want to know more? Contact us!" />
          <ContactMaricaCard />
        </AlignmentContainer>
      </Flex>
    </Section>
  );
};
