import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { body } from "../../../constants/fonts";

export interface IDotNotationItemProps {
  text: string;
}

export const DotNotationItem: React.FC<IDotNotationItemProps> = ({ text }) => {
  return (
    <Box display="flex" flexDirection="row" gap="1rem">
      <Text
        fontSize={{
          base: "0.875rem",
          lg: "1rem",
        }}
        fontFamily={body}
        fontWeight="500"
      >
        &#x2022;
      </Text>
      <Text
        fontSize={{
          base: "0.875rem",
          lg: "1rem",
        }}
        fontFamily={body}
        fontWeight="500"
      >
        {text}
      </Text>
    </Box>
  );
};
