import React from "react";
import { Layout } from "../components/layout/Layout";
import { UiSection } from "../components/sections/careers/uiDesigner/UiSection";
import { UiHero } from "../components/sections/careers/uiDesigner/UiHero";
import { MetaTags } from "../components/MetaTags";

const UiDesignerPage: React.FC = () => {
  return (
    <Layout
      colorScheme="secondary"
      heroSection={<UiHero />}
      isHeroShortBaseOnImage
      isWhite
    >
      <UiSection />
    </Layout>
  );
};

export default UiDesignerPage;

export const Head = () => <MetaTags title="UiDesignerPage" />;
