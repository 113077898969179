import { Box } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const UiHero: React.FC = () => {
  return (
    <Box
      width={{ base: "100%" }}
      maxHeight="40rem"
      paddingTop="1rem"
      margin="0 auto"
    >
      <StaticImage
        layout="fullWidth"
        transformOptions={{ fit: "contain", cropFocus: "attention" }}
        src="../../../../images/indevit-04.jpg"
        alt=""
        placeholder="blurred"
        style={{ width: "100%", height: "100%" }}
      />
    </Box>
  );
};
